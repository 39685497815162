@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css);
/* Import Fonts */
@font-face {
  font-family: SF-Pro;
  src: url(/static/media/SFPRODISPLAYREGULAR.d1f5e6e1.OTF);
}

@font-face {
  font-family: SF-light;
  src: url(/static/media/SF_Pro_Display.488b5ff7.woff);
}

@font-face {
  font-family: SF-Bold;
  src: url(/static/media/SFPRODISPLAYBOLD.b1f4d2c6.OTF);
}

/* Import Font Awesome */
/* Import Animate CSS */

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: SF-Pro !important;
  color: white !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Color Palette */
:root {
  --primary-color: #050514;
  --richmond-blue: #5e56ff;
  --mahinda-gold: #FFB800;
  --black: #000000;
  --white: #ffffff;
}

/* variable for a color */


.has-blue-background {
  background-color: var(--primary-color) !important;
}

.has-richmond-blue-background {
  background-color: var(--richmond-blue) !important;
}

.has-gold-background {
  background-color: var(--mahinda-gold) !important;
}

.rl-inner-sponser-container {
  padding: 30px 0;
}

.rl-sponsor-cont {
  text-align: center;
}

.rl-sponsor-cont-topic {
  padding: 10px 0;
}

.has-white-text {
  color: var(--white) !important;
}

.low-opacity-text {
  opacity: 0.5 !important;
}

/* Styles */
.rl-home-main {
    min-height: 100vh !important;
    background: url(/static/media/home-bg.8f92edd2.png);
    background-repeat: no-repeat;
    /* background-position: top right; */
    background-size: contain;
    left: 0;
    top: 0;
    background-color: var(--primary-color) !important;
}

.home-content-column {
    padding-left: 8rem !important;
}

.home-columns {
    min-height: 105vh;
    /* height: 98vh; */
}

.home-columns>.column {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.home-scorecard-btn {
    background-color: transparent !important;
    border: 1px solid #fff !important;
    color: #fff !important;
}

.home-hero-bottom {
    margin-top: 5rem !important;
}

.home-scorecard-btn-text {
    font-size: 12px !important;
}

.home-scorecard {
    background: rgba(255, 255, 255, 0.05) !important;
    box-shadow: 0px 60px 70px -35px rgba(0, 0, 0, 0.25);
    border-radius: 23px;
    width: 32rem;
    padding: 25px;
    margin-top: 1%;
}

.passive-scorecard-top-right {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #9ad2da;
    color: #000;
    padding: 1px 20px;
}

.passive-scorecard-tagline {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-radius: 5px;
}

.rcg-tagline {
    background-color: #eb3838;
    color: #fff;
}

.mcg-tagline {
    background-color: #FFB800;
    color: #000;
}

.draw-tagline {
    background-color: #dddddd;
    color: #000 !important;

}

.home-crest-container {
    background: white;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 9px;
}

.video-holder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 80%;
    height: 60%;
    position: relative;
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
}

.sponsors-section {
    background-color: var(--primary-color) !important;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 7rem;
    padding-right: 7rem;
}

.sponsor-grid {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-gap: 1rem;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(300px, 2fr));
}

.sponsor-card {
    background: rgba(255, 255, 255, 0.05) !important;
    box-shadow: 0px 60px 70px -35px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 2rem;
    justify-content: center;
    text-align: center;
    cursor: pointer;
}

.sponsor-card .top-right {
    position: absolute;
    top: 0;
    right: 0;
    background-color: red;
    color: #000;
    padding: 1px 20px;
}

.gold-sponsor {
    background-color: #FFB800 !important;
    color: #000 !important;
}

.silver-sponsor {
    background-color: #dddddd !important;
    color: #000 !important;
}

.silver-sponsor p {
    color: #000 !important;
}

.platinum-sponsor {
    background-color: #9ad2da !important;
    color: #000 !important;
}

.platinum-sponsor p {
    color: #000 !important;
}

.bronze-sponsor {
    background-color: #ff8800 !important;
    color: #000 !important;
}

.broadcast-sponsor {
    background-color: #eb3838 !important;
    color: #fff !important;
}

.sponsor-card:hover {
    background: rgba(255, 255, 255, 0.1) !important;
}

.sponsor-img {
    height: 5rem
}

.site-footer {
    background-color: var(--primary-color) !important;
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: center;
}

.Toastify__toast {
    padding: 0 !important;
}

.Toastify__toast-body {
    padding: 0 !important;
}

.Toastify__toast-theme--dark {
    background-color: #000 !important;
}

.Toastify__close-button {
    position: absolute;
    z-index: 100;
    right: 0;
}

.home-scorecard-logo {
    width: 1.8rem;
}

@media only screen and (max-width: 768px) {
    .home-content-column {
        padding-left: 3rem !important;
    }

    .video-column {
        height: 40%;
    }

    .rl-home-main {
        background-size: cover;
    }
}

@media only screen and (max-width: 580px) {
    .home-content-column {
        padding-left: 2rem !important;
        padding-right: 2rem !important;
    }

    .sponsors-section {
        padding-left: 2rem !important;
        padding-right: 2rem !important;
    }

    .home-scorecard {
        width: 100%;
    }
}

@media only screen and (max-width: 450px) {
    .home-scorecard-logo {
        width: 1.5rem
    }

    .home-crest-container {
        width: 40px;
        height: 40px;
    }
}

@media only screen and (max-width: 471px) {
    .home-columns {
        min-height: 118vh !important;
    }
}
.navbar {
    background-color: transparent !important;
    padding-top: 1rem;
}

.navbar-item {
    color: #fff !important;
    font-weight: 500 !important
}

.navbar-item:hover {
    background-color: transparent !important;
}

.navbar-item:focus-within {
    background-color: transparent !important;
}

.navbar-menu {
    padding-left: 7rem;
    padding-right: 7rem;
}

.navbar-icon {
    color: #fff !important;
    font-size: 20px;
}

.navbar-menu {
    background-color: transparent !important;
}

.navbar-menu.is-active {
    background-color: #00000066 !important;
    text-align: center !important;
}
.score-card-page {
    background-color: var(--primary-color);
    background: url(/static/media/home-bg.8f92edd2.png);
    background-repeat: no-repeat;
    background-size: cover;
    left: 0;
    top: 0;
    min-height: 100vh;
}

.main-score-container {
    min-height: 10rem;
    margin-left: 7rem !important;
    margin-right: 7rem !important;
    border-radius: 25px !important;
    border: 1px solid rgba(255, 255, 255, 0.464);
}

.scorecard-notification {
    border: 1px solid rgba(255, 255, 255, 0.464);
    display: none;
}

.score-accordians-section {
    margin-top: 2rem !important;
    margin-left: 7rem !important;
    margin-right: 7rem !important;
}

.accordion__button {
    background-color: var(--primary-color) !important;
    border: 1px solid rgba(255, 255, 255, 0.464) !important;
    color: #fff !important;
    font-weight: 500 !important;
    border-radius: 15px !important;
}


@media only screen and (max-width: 769px) {
    .main-score-container {
        margin-left: 2rem !important;
        margin-right: 2rem !important;
    }

    .score-accordians-section {
        margin-left: 2rem !important;
        margin-right: 2rem !important;
    }

}

@media only screen and (max-width: 671px) {
    .richmond-logo-scorecard {
        display: none !important;
    }

    .color-block {
        display: block !important;
    }
}

@media only screen and (max-width: 663px) {
    .score-accordians-section {
        display: none !important;
    }

    .scorecard-notification {
        display: block !important;
    }
}
.color-block {
    width: 0.5rem, !important;
    height: 5rem;
    display: none;
}

.live-text {
    color: #f90205 !important;
    text-shadow: 0 0 20px #f90205;
}

.richmond-logo-scorecard {
    height: 5rem !important;
}

.live-text::before {
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #f90205;
    margin-right: 8px;
}
.title-column{
    background-color: rgba(255, 255, 255, 0.17);
    border-radius: 7px;
}

@media only screen and (max-width: 733px) {
    .title-column h1{
        font-size: 12px !important;
    }
    .singe-inning-card h1{
        font-size: 12px !important;
    }
    .innings-final-scores h1{
        font-size: 12px !important;
    }
}
@media only screen and (max-width: 733px) {
    .bowling-card-content h1 {
        font-size: 12px !important;
    }
}
.Toastify__toast-theme--dark{
    background-color: var(--white) !important;
}
.Toastify__toast-icon{
    display: none !important;
}
.blogs-page {
    min-height: 100vh;
    background: url(/static/media/home-bg.8f92edd2.png);
    background-repeat: no-repeat;
    background-size: cover;
    left: 0;
    top: 0;
    background-color: var(--primary-color) !important;
}

.blog-card-grid {
    margin-top: 4rem !important;
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-gap: 1rem;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(300px, 2fr));
}

.blog-author {
    height: 5rem;
}

.blog-page-column-content {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
}

.left-blog-card {
    background: rgba(255, 255, 255, 0.05) !important;
    box-shadow: 0px 60px 70px -35px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.left-blog-card-active {
    border: 1px solid var(--richmond-blue) !important;
}

.left-blog-card h3, .left-blog-card p {
    color: #fff !important;
}

.right-blog-card {
    background: rgba(255, 255, 255, 0.05) !important;
    box-shadow: 0px 60px 70px -35px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 2rem;
}

.blog-rich-text p {
    margin-bottom: 10px;
}

.blog-site-footer {
    background-color: transparent !important;
}
