.score-card-page {
    background-color: var(--primary-color);
    background: url("../../assets//img//home-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    left: 0;
    top: 0;
    min-height: 100vh;
}

.main-score-container {
    min-height: 10rem;
    margin-left: 7rem !important;
    margin-right: 7rem !important;
    border-radius: 25px !important;
    border: 1px solid rgba(255, 255, 255, 0.464);
}

.scorecard-notification {
    border: 1px solid rgba(255, 255, 255, 0.464);
    display: none;
}

.score-accordians-section {
    margin-top: 2rem !important;
    margin-left: 7rem !important;
    margin-right: 7rem !important;
}

.accordion__button {
    background-color: var(--primary-color) !important;
    border: 1px solid rgba(255, 255, 255, 0.464) !important;
    color: #fff !important;
    font-weight: 500 !important;
    border-radius: 15px !important;
}


@media only screen and (max-width: 769px) {
    .main-score-container {
        margin-left: 2rem !important;
        margin-right: 2rem !important;
    }

    .score-accordians-section {
        margin-left: 2rem !important;
        margin-right: 2rem !important;
    }

}

@media only screen and (max-width: 671px) {
    .richmond-logo-scorecard {
        display: none !important;
    }

    .color-block {
        display: block !important;
    }
}

@media only screen and (max-width: 663px) {
    .score-accordians-section {
        display: none !important;
    }

    .scorecard-notification {
        display: block !important;
    }
}