.rl-home-main {
    min-height: 100vh !important;
    background: url("../../assets/img//home-bg.png");
    background-repeat: no-repeat;
    /* background-position: top right; */
    background-size: contain;
    left: 0;
    top: 0;
    background-color: var(--primary-color) !important;
}

.home-content-column {
    padding-left: 8rem !important;
}

.home-columns {
    min-height: 105vh;
    /* height: 98vh; */
}

.home-columns>.column {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.home-scorecard-btn {
    background-color: transparent !important;
    border: 1px solid #fff !important;
    color: #fff !important;
}

.home-hero-bottom {
    margin-top: 5rem !important;
}

.home-scorecard-btn-text {
    font-size: 12px !important;
}

.home-scorecard {
    background: rgba(255, 255, 255, 0.05) !important;
    box-shadow: 0px 60px 70px -35px rgba(0, 0, 0, 0.25);
    border-radius: 23px;
    width: 32rem;
    padding: 25px;
    margin-top: 1%;
}

.passive-scorecard-top-right {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #9ad2da;
    color: #000;
    padding: 1px 20px;
}

.passive-scorecard-tagline {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-radius: 5px;
}

.rcg-tagline {
    background-color: #eb3838;
    color: #fff;
}

.mcg-tagline {
    background-color: #FFB800;
    color: #000;
}

.draw-tagline {
    background-color: #dddddd;
    color: #000 !important;

}

.home-crest-container {
    background: white;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 9px;
}

.video-holder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 80%;
    height: 60%;
    position: relative;
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
}

.sponsors-section {
    background-color: var(--primary-color) !important;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 7rem;
    padding-right: 7rem;
}

.sponsor-grid {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(300px, 2fr));
}

.sponsor-card {
    background: rgba(255, 255, 255, 0.05) !important;
    box-shadow: 0px 60px 70px -35px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 2rem;
    justify-content: center;
    text-align: center;
    cursor: pointer;
}

.sponsor-card .top-right {
    position: absolute;
    top: 0;
    right: 0;
    background-color: red;
    color: #000;
    padding: 1px 20px;
}

.gold-sponsor {
    background-color: #FFB800 !important;
    color: #000 !important;
}

.silver-sponsor {
    background-color: #dddddd !important;
    color: #000 !important;
}

.silver-sponsor p {
    color: #000 !important;
}

.platinum-sponsor {
    background-color: #9ad2da !important;
    color: #000 !important;
}

.platinum-sponsor p {
    color: #000 !important;
}

.bronze-sponsor {
    background-color: #ff8800 !important;
    color: #000 !important;
}

.broadcast-sponsor {
    background-color: #eb3838 !important;
    color: #fff !important;
}

.sponsor-card:hover {
    background: rgba(255, 255, 255, 0.1) !important;
}

.sponsor-img {
    height: 5rem
}

.site-footer {
    background-color: var(--primary-color) !important;
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: center;
}

.Toastify__toast {
    padding: 0 !important;
}

.Toastify__toast-body {
    padding: 0 !important;
}

.Toastify__toast-theme--dark {
    background-color: #000 !important;
}

.Toastify__close-button {
    position: absolute;
    z-index: 100;
    right: 0;
}

.home-scorecard-logo {
    width: 1.8rem;
}

@media only screen and (max-width: 768px) {
    .home-content-column {
        padding-left: 3rem !important;
    }

    .video-column {
        height: 40%;
    }

    .rl-home-main {
        background-size: cover;
    }
}

@media only screen and (max-width: 580px) {
    .home-content-column {
        padding-left: 2rem !important;
        padding-right: 2rem !important;
    }

    .sponsors-section {
        padding-left: 2rem !important;
        padding-right: 2rem !important;
    }

    .home-scorecard {
        width: 100%;
    }
}

@media only screen and (max-width: 450px) {
    .home-scorecard-logo {
        width: 1.5rem
    }

    .home-crest-container {
        width: 40px;
        height: 40px;
    }
}

@media only screen and (max-width: 471px) {
    .home-columns {
        min-height: 118vh !important;
    }
}